<template>
    <v-container fluid>
        <headerVue :title="'Marcas'" />
        <v-row align-content="center" justify="end">
            <v-col md="3">
                <a href="/crear-marca">
                    <v-btn block color="primary" elevation="2" x-large>Crear Marca</v-btn>
                </a>
            </v-col>
            <v-col md="3" offset-md="6">
                <v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        <v-row>

			<v-col cols="12" style="text-align:center;">
				<v-progress-circular v-if="loading"
				:size="70"
				:width="7"
				color="purple"
				indeterminate></v-progress-circular>

				<v-alert v-if="deleteSuccess" type="success">La marca fue eliminada</v-alert>
			</v-col>

            <v-col cols="12" v-if="!loading">
                <v-data-table
                    :headers="headers"
                    :items="brands"
                    :search="search"
                    class="elevation-2 text--dark table-striped"
                    loading-text="Cargando datos... Por favor espere"
                    :items-per-page="5"
                    :no-results-text="'No se encontraron marcas'"
                    :no-data-text="'No hay datos'"
                    :footer-props="{
                    'items-per-page-text': 'marcas por página',
                    'items-per-page': [5, 10, 15],
                    'items-per-page-all-text': 'Todos',
                    'items-per-page-options': [5, 10],
                    'loading-text': 'Obteniendo datos... Por favor espere',
                    'no-data-text': 'No hay datos...',
                    'no-results-text': 'No se encontraron marcas',
                    }">
                    <template v-slot:no-data>
                        <v-alert :value="true" color="#ffffff">
                            <p style="color: #ff0000">No hay marcas registrados...</p>
                        </v-alert>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td class="text-sm-center">{{ item.id }}</td>
                                <td class="text-sm-center">{{ item.nombreDeMarca }}</td>
                                <td class="justify-center layout px-0">
                                    <v-icon color="gray" @click="edit(item.id)">mdi-pencil-outline</v-icon>
                                    <v-icon color="red" @click="deleteMachine(item.id)">mdi-trash-can</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import headerVue from "../../components/header.vue"
    export default {
      components: {
        headerVue,
      },
      data() {
        return {
			deleteSuccess: false,
			loading: true,
        	search: "",
          headers: [
            {
              text: "Id",
              align: "center",
              sortable: false,
              value: "id",
            },
            { text: "Marca", align: "center", value: "nombreDeMarca" },
            { text: "Opciones", align: "center", sortable: false },
          ],
          brands: [],
          back: "",
        }
      },
      methods: {
        deleteMachine(id) {

		  	this.deleteSuccess = true;
			this.brands = this.brands.filter((brand) => brand.id !== id);

			axios.delete( 'https://consubanco.azurewebsites.net/api/Marcas/' + id )
			.then(resp => {
				
				window.location.reload();
			})
			.catch(err => {
				
				console.warn(err);
			});
        },
        edit(id) {
          
		  this.$router.push( "/editar-marca/"+ id );
        },
        inicialization() {

            const headerCurrent = {
				'Content-type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true'
			}
          
          axios.get( 'https://consubanco.azurewebsites.net/api/Marcas',{
					headers: headerCurrent
				})
          .then(resp => {
              this.brands = resp.data;
              this.loading = false;
          })
          .catch(err => {
              // Handle Error Here
              console.error(err);
          });
          //console.log( "brands => ", this.brands);
        },
      },
      mounted() {
        this.inicialization()
      },
    }
</script>